<template>
    <div class="access-tab-container">
        <div class="access-tab-titles">
            <div class="access-tab-titles-access">
                <p>{{ $t("global.entities.access") }}</p>
            </div>
            <div class="access-tab-titles-keypad">
                <p>{{ $t("codesList.keypadsCode") }}</p>
            </div>
            <div class="access-tab-titles-phonecode">
                <p>{{ $t("codesList.phoneCode") }}</p>
            </div>
        </div>
        <div
            v-for="item in data"
            :key="item"
            class="access-tab-content"
            @click="
                goToV1View(
                    'data/codes/codes/id=' + item.id + '&ret=codes_acces'
                )
            "
        >
            <div class="access-tab-content-access">
                <p>{{ item.nom }}</p>
            </div>
            <div class="access-tab-content-keypad">
                <p>{{ item.keypads_count }}</p>
            </div>
            <div class="access-tab-content-phonecode">
                <p>{{ item.phonecodes_count }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import v1mixin from "@/mixins/v1.js"
export default {
    name: "CodeByAccessList",
    mixins: [v1mixin],
    props: {
        data: {
            type: Object,
            default: () => {},
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/details.scss";
p {
    margin: 0 !important;
}
.access-tab-container {
    width: 100%;
    @media screen and (max-width: 768px) {
        min-width: max-content;
    }
    .access-tab-titles {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        font-size: $normal;
        font-weight: 800;
        padding-bottom: 0.5em;
        border-bottom: 1px solid $grey-neutral;

        @media screen and (max-width: 425px) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media screen and (max-width: 350px) {
            grid-template-columns: repeat(3, 1fr);
        }

        .access-tab-titles-access {
            grid-column: span 3 / span 3;
            @media screen and (max-width: 768px) {
                max-width: 120px;
                word-break: break-all;
                left: 0;
                background-color: $white;
            }

            @media screen and (max-width: 425px) {
                grid-column: span 2 / span 2;
            }

            @media screen and (max-width: 350px) {
                grid-column: span 1 / span 1;
                max-width: 70px;
            }
        }
    }

    .access-tab-content {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        font-size: $small;
        align-items: center;
        font-weight: 500;
        padding: 1em 0;
        border-bottom: 1px solid $grey-neutral;
        transition: 0.1s all ease-in-out;

        &:hover {
            cursor: pointer;
            background-color: $grey-light;
            transition: 0.1s all ease-in-out;
        }

        @media screen and (max-width: 425px) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media screen and (max-width: 350px) {
            grid-template-columns: repeat(3, 1fr);
        }

        .access-tab-content-access {
            grid-column: span 3 / span 3;

            @media screen and (max-width: 768px) {
                max-width: 120px;
                word-break: break-all;
                left: 0;
                background-color: $white;
            }

            @media screen and (max-width: 425px) {
                grid-column: span 2 / span 2;
                max-width: 120px;
                word-break: break-all;
                left: 0;
                background-color: $white;
            }

            @media screen and (max-width: 350px) {
                grid-column: span 1 / span 1;
                max-width: 70px;
            }
        }
    }
}
</style>
