<template>
    <div class="agences-list">
        <h1>{{ $t('agencesList.title', {count: agencesCount}) }}</h1>
        <table class="table agences-table">
            <thead>
                <tr>
                    <th class="mobile"></th>
                    <th>{{ $t('agencesList.arrayColumns.name') }}</th>
                    <th class="mobile">{{ $t('agencesList.arrayColumns.adress') }}</th>
                    <th class="mobile">{{ $t('agencesList.arrayColumns.comment') }}</th>
                    <th rowspan="2" class="mobile"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in agencesList" :key="item.id">
                    <td class="mobile"><i class="fas fa-address-book"></i></td>
                    <td>{{ item.nom }}</td>
                    <td class="mobile">{{ item.adr }}</td>
                    <td class="mobile">{{ item.com }}</td>
                    <td rowspan="2" class="mobile"></td>
                </tr>
            </tbody>
        </table>

      <pagination
          v-show="agencesPagesCount > 1"
          :pagesCount="agencesPagesCount"
          :page="agencesCurrentPage"
          v-on:new-current-page="agencesCurrentPage = $event"
      >
      </pagination>
    </div>
    <div class="subaccounts-list">
        <h1>{{ $t('subaccountsList.title', {count: subaccountsCount}) }}</h1>
        <table class="table subaccounts-table">
            <thead>
                <tr>
                    <th class="mobile"></th>
                    <th>{{ $t('subaccountsList.arrayColumns.username') }}</th>
                    <th>{{ $t('subaccountsList.arrayColumns.name') }}</th>
                    <th class="mobile">{{ $t('subaccountsList.arrayColumns.fisrtName') }}</th>
                    <th class="mobile">{{ $t('subaccountsList.arrayColumns.email') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in subaccountsList" :key="item.id" @click="goToSubAccount(item.id)" :class="(checkRight('G_USERS')) ? 'Lineclic' : 'Linenoclic'">
                    <td class="mobile"><i class="fas fa-user"></i></td>
                    <td>{{ item.user }}</td>
                    <td>{{ item.nom }}</td>
                    <td class="mobile">{{ item.prenom }}</td>
                    <td class="mobile">{{ item.email }}</td>
                </tr>
            </tbody>
        </table>

      <pagination
          v-show="subaccountsPagesCount > 1"
          :pagesCount="subaccountsPagesCount"
          :page="subaccountsCurrentPage"
          v-on:new-current-page="subaccountsCurrentPage = $event"
      >
      </pagination>
    </div>
</template>

<script>
import v1mixin from '@/mixins/v1.js'
import Pagination from "@/components/basic/Pagination";
import {getResidenceSubaccountsById} from "@/services/intratone/residence";

export default {
    name: 'SubaccountsList',
  components: {
    Pagination
  },
    mixins: [
        v1mixin
    ],
    props: {
        subaccounts: {
            type: Object,
            default() {
                return {}
            }
        },
        agences: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
          subaccountsList: [],
          agencesList: [],
          subaccountsCurrentPage: 1,
          subaccountsPagesCount: 1,
          subaccountsCount: 0,
          limit_subaccounts: 2,
          agencesCurrentPage: 1,
          agencesPagesCount: 1,
          agencesCount: 0,
          limit_agences: 2,
          limitPerPage: 2,
        }
    },
    methods: {
      async getsubAccoutList() {
        const params = {
          limit_subaccounts: this.limit_subaccounts,
          page_subaccounts: this.subaccountsCurrentPage,
          limit_agences: this.limit_agences,
          page_agences: this.agencesCurrentPage
        }

        let subaccountsResponse = await getResidenceSubaccountsById(this.$route.params.id, params)

        if (subaccountsResponse === null) {
          throw new Error()
        }
        const {agences, subaccounts} = subaccountsResponse.data

        this.subaccountsList = subaccounts['list']
        this.subaccountsPagesCount = subaccounts['_pages']
        this.agencesList = agences['list']
        this.agencesPagesCount = agences['_pages']
      },
      goToSubAccount(id) {
          if (this.checkRight('G_USERS')) {
            this.goToV1View('data/gestionnaire/list/target=gestionnaire§compte§list&targetArgs=id=' + id)
          } else {
            return
          }
      }
    },
  watch: {
      subaccounts: {
        handler: function () {
          this.subaccountsList = this.subaccounts['list']
          this.subaccountsPagesCount = this.subaccounts['_pages']
          this.limit_subaccounts = this.subaccounts['_limit']
          this.subaccountsCount = this.subaccounts['_count']
        },
        deep: true
      },
      agences: {
        handler: function () {
          this.agencesList = this.agences['list']
          this.agencesPagesCount = this.agences['_pages']
          this.limit_agences = this.agences['_limit']
          this.agencesCount = this.agences['_count']
        },
        deep: true
      },
      subaccountsCurrentPage: {
        handler: function () {
          this.getsubAccoutList()
        }
      },
    agencesCurrentPage: {
        handler: function () {
          this.getsubAccoutList()
        }
      }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

@media all and (max-width: 768px) {
    .mobile {
        display: none;
    }
}
.subaccounts-list,.agences-list {
    padding: 30px 0 30px 0;
    border-radius: 30px;
    box-shadow: 0px 3px 30px 0 rgb(0 0 0 / 15%);
    margin:2% auto;
    width: 100%;
    h1 {
        font-size: $big;
        font-family: 'Avenir Heavy';
        text-align: center;
        margin-bottom: 35px;
    }

    .subaccounts-table,.agences-table {
        border-color: $grey-neutral;
        color: $blue-dark;
        width: 90%;
        margin-left: auto;
        margin-right: auto;

        thead {
            border: none;

            tr {
                border-color: $grey-neutral;

                th {
                    font-size: $normal;
                }

            }
        }

        tbody {
                border-color: $grey-neutral;

            tr {
                border-color: $grey-neutral;
                transition: background-color 100ms, color 100ms;

                td {
                    vertical-align: middle;
                    padding: 10px;
                    font-size: $normal;
                }

                td:first-child {
                    text-align: center;
                    width:10%;
                }
            }

            tr:hover {
                background-color: $grey-light;
                color: $orange-neutral;
            }

            .Lineclic:hover {
              cursor:pointer;
            }

            .Linenoclic:hover {
              cursor:unset;
            }
        }
    }
}

</style>
