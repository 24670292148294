<template>
  <div class="display-mobile" v-if="!closeV1warning">
    <div class="icons">
      <i class="fas fa-exclamation-triangle warning" />
      {{ $t("alerts.no_mobile_yet") }}
    </div>
    <div class="close" @click="closeWarning()">
      <i class="fas fa-times" title="close"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "v3Warning",
  data() {
    return {
      closeV1warning: false,
    };
  },
  mounted() {
    if (location.href.search("/print/") > 0) {
      this.closeV1warning = true;
    } else if (localStorage.getItem("closeV1warning")) {
      this.closeV1warning = this.checkVersion();
    }
  },
  watch: {
    closeV1warning() {
      localStorage.setItem(
        "closeV1warning",
        this.$store.getters["account/getVersion"]
      );
    },
  },
  methods: {
    closeWarning() {
      this.closeV1warning = this.$store.getters["account/getVersion"];
      this.$store.dispatch(
        "account/setWarning",
        this.$store.getters["account/getVersion"]
      );
    },
    checkVersion() {
      return (
        localStorage.getItem("closeV1warning") ===
        this.$store.getters["account/getVersion"]
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss";

.display-mobile {
  @media all and (max-width: 1024px) {
    border: solid 2px $tag-red;
    border-radius: 15px;
    margin: 10px;
    color: $tag-red;
    background-color: rgba(205, 97, 85, 0.5);
    display: flex;
    //flex-direction: column;
    justify-content: center;
    gap: 1em;
    padding: 5px;
    .icons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .warning {
        color: $tag-red;
        font-size: 22px;
      }
      .close {
        color: $black;
        width: 30px;
        height: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        cursor: pointer;
      }
    }
    .close{
        cursor: pointer;
    }
  }
  @media all and (min-width: 1024px) {
    display: none;
  }
}
</style>
