import httpClient from "../httpClient"

import { formatData } from "../request"
import { encodeRequestParameters } from "../request"

import {
    centralUnitsByResidenceFormat,
    CentralUnitsFormat,
    outdatedsFormat,
} from "../formats"

/**
 * Returns the central units of a residence
 */
export function getCentralUnitsByResidence(data) {
    data = formatData(centralUnitsByResidenceFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .get("/centrale", { params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

/**
 * Returns the central unit by id
 */
export function getCentraleById(id) {
    return httpClient
        .get("/centrale/" + id)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function printCentralUnitsByResidence(data) {
    data = formatData(CentralUnitsFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)

    const fileName = "centrales.xlsx"
    const apiEndpoint = "/centrales/export"
    const header = {
        Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    }
    return httpClient
        .get(apiEndpoint, { params, header, responseType: "arraybuffer" })
        .then((response) => {
            const url = window.URL.createObjectURL(
                new Blob([response], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })
            )
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", fileName)
            document.body.appendChild(link)
            link.click()
        })
        .catch((error) => console.log(error))
}

export function getCentraleOutdateds(data) {
    data = formatData(outdatedsFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .get("/centrale/outdateds", { params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function exportCentraleOutdateds(params) {
    return httpClient
        .get("/centrales/outdateds/export", { responseType: "blob", params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

/**
 *
 * Verifies the serial of a new centrale for remplacement
 */
export function verifyNewCentraleSerial(id, data, jwt) {
    data = formatData(outdatedsFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)

    return httpClient
        .post(`/centrale/${id}/contract/verify`, params)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

/**
 *
 * Verifies the Clemobils list which will be updated with new centrale contract
 */
export function verifyClemobilList(id, jwt) {
    return httpClient
        .get(`/centrales/${id}/outdateds/reminder`, { responseType: "blob" })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

/**
 * Update the contract
 */
export function updateContract(id, data, jwt) {
    data = formatData(outdatedsFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .post(`/centrale/${id}/contract/renew`, params)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}
